<template>
	<v-container fluid>
		<v-form ref="formulario" v-if="Object.keys(estudio).length > 0">
			<div class="d-flex flex-wrap mb-4" v-if="!noEdit">
				<v-tooltip v-if="!estudio.estado || estudio.estado == 'Borrador'" bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							v-on="on"
							v-bind="attrs"
							class="mx-1"
							color="primary"
							large
							:loading="subiendoEstudio"
							@click="enviarEstudio(true)"
						>
							<v-icon color="white" left>mdi-content-save</v-icon>GUARDAR BORRADOR
						</v-btn>
					</template>
					<span>Guardar el estudio temporalmente como borrador</span>
				</v-tooltip>
				<v-tooltip v-if="idEstudio" bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							v-on="on"
							v-bind="attrs"
							class="mx-1"
							color="primary"
							large
							:loading="subiendoEstudio"
							@click="enviarEstudio(false)"
							:disabled="documentos.length == 0 && !ignorarDoc"
						>
							<v-icon color="white" left>mdi-upload</v-icon>
							{{ estudio.estado == 'Borrador' ? 'ENVIAR A REVISIÓN' : 'GUARDAR CAMBIOS' }}
						</v-btn>
					</template>
					<span>Validar los datos y enviar a revisión</span>
				</v-tooltip>
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							v-on="on"
							v-bind="attrs"
							large
							text
							class="mx-1"
							:loading="subiendoEstudio"
							@click="erroresCampos = !$refs.formulario.validate()"
							:color="
								erroresCampos == null ? '' : erroresCampos ? 'error' : 'success'
							"
						>
							<v-icon left v-if="erroresCampos === true">mdi-alert-circle-outline</v-icon>
							<v-icon left v-else-if="erroresCampos === false">mdi-check</v-icon>Consultar campos
						</v-btn>
					</template>
					<span v-if="!erroresCampos">Consultar campos faltantes o erroneos</span>
					<span v-else>Hay campos erróneos, revisa todos los campos</span>
				</v-tooltip>
				<v-spacer></v-spacer>

				<v-tooltip v-if="idEstudio && (  estudio.estado == 'Borrador'  )" bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							v-on="on"
							v-bind="attrs"
							class="mx-1"
							color="error"
							large
							:loading="subiendoEstudio"
							@click="eliminarEstudio(false)"
						>
							<v-icon color="white" left>mdi-delete</v-icon>ELIMINAR BORRADOR
						</v-btn>
					</template>
					<span>Eliminar borrador</span>
				</v-tooltip>
			</div>

			<div style="gap: 10px" :class="[noEdit || 'my-4', 'd-flex flex-wrap', 'align-center']">
				<v-alert class="mb-0" dense :icon="getSituacionIcon(estudio.estado).icon" :color="getSituacionIcon(estudio.estado).color" text v-if="estudio.estado && estudio.estado != 'Borrador'">{{ estudio.estado }}</v-alert>
				<v-alert class="mb-0" dense type="info" text v-if="tipoSeleccionado">{{ tipoSeleccionado.nombreTipo }}</v-alert>
				<v-alert class="mb-0" dense type="info" text v-if="!idEstudio">
					La documentación no está disponible hasta que el borrador esté
					creado
				</v-alert>

				<v-alert
					class="mb-0"
					dense
					type="warning"
					text
					v-else-if="documentos.length == 0 && !ignorarDoc"
				>Es obligatorio adjuntar la documentación</v-alert>

				<v-checkbox label="Ignorar documentación" dense class="ma-0" hide-details v-model="ignorarDoc" v-if="$root.acceso('EDITAR_ESTUDIOS')" ></v-checkbox>

				<v-alert
					v-for="(error, index) in errors"
					:key="index"
					type="error"
					text
					class="ma-0"
				>{{ error }}</v-alert>
			</div>

			<v-tabs v-model="tab">
				<v-tab>Proyecto</v-tab>
				<v-tab>Factura Datos</v-tab>
				<v-tab>Geolocalización</v-tab>
			</v-tabs>

			<v-tabs-items class="pa-2" touchless v-model="tab">
				<v-tab-item eager>
					<v-row>
						<v-col cols="12" md="6">
							<tarjeta-datos header titulo="Descripción del proyecto" icon="format-list-bulleted">
								<aux-input title="Nombre proyecto (Cliente)">
									<v-text-field
										filled
										dense
										hide-details="auto"
										:rules="[rules.req]"
										v-model="estudio.nombreProyecto"
									></v-text-field>
								</aux-input>
								<v-divider />
								<aux-input title="Descripción proyecto & observaciones">
									<v-textarea
										filled
										dense
										hide-details="auto"
										no-resize
										:rules="[rules.req]"
										v-model="estudio.descripcionProyecto"
									></v-textarea>
								</aux-input>
								<!-- <v-divider />
								<aux-input title="Formato estudio (opcional)">
									<v-text-field filled dense hide-details="auto" clearable v-model="estudio.formatoEstudio"></v-text-field>
								</aux-input> -->
							</tarjeta-datos>
						</v-col>
						<v-col v-show="idEstudio" cols="12" md="6">
							<tarjeta-datos header titulo="Documentación" icon="file-document">
								<DocumentacionEstudio :idEstudio="idEstudio" v-model="documentos" />
							</tarjeta-datos>
						</v-col>

						<v-col cols="12" md="6" v-if="$root.acceso('EDITAR_ESTUDIOS')">
							<tarjeta-datos class="mt-6" icon="solar-panel" titulo="Datos estudio" >
								<aux-input title="Potencia estudio">
									<v-text-field dense hide-details="auto" filled placeholder="Potencia estudio" v-model="estudio.potenciaEstudio"></v-text-field>
								</aux-input>
								<v-divider></v-divider>
								<aux-input title="Precio Pico">
									<v-text-field dense hide-details="auto" filled placeholder="Precio Pico" v-model="estudio.precioPico"></v-text-field>
								</aux-input>
								<v-divider></v-divider>
								<aux-input title="Precio Total">
									<v-text-field dense hide-details="auto" filled placeholder="Precio Total" v-model="estudio.precioTotal"></v-text-field>
								</aux-input>
							</tarjeta-datos>
						</v-col>

					</v-row>
				</v-tab-item>

				<v-tab-item eager>
					<v-row>
						<v-col cols="12" md="6">
							<tarjeta-datos header icon="domain" titulo="Datos suministro">
								<aux-input title="CUPS *">
									<v-text-field
										dense
										hide-details="auto"
										filled
										placeholder="CUPS"
										v-model="estudio.suministro.cups"
										:rules="[rules.cups, rules.req]"
										class="mb-1"
									>
										<template v-slot:append-outer>
											<v-btn class="mt-n2" color="primary" @click="buscarCups" :loading="isLoading">
												<v-icon>mdi-magnify</v-icon>
											</v-btn>
										</template>
									</v-text-field>

									<div class="d-flex align-center" v-for="(c, i) in estudio.suministro.cupsExtras">
										<v-text-field
											filled
											dense
											v-model="estudio.suministro.cupsExtras[i]"
											hide-details="auto"
											class="my-1"
										/>
										<v-btn @click.stop="estudio.suministro.cupsExtras.splice(i, 1)" color="error" icon small>
											<v-icon small>mdi-minus</v-icon>
										</v-btn>
									</div>
									<v-btn
										class="mt-2"
										@click.stop="estudio.suministro.cupsExtras.push(null)"
										fab
										small
										color="primary"
									>
										<v-icon>mdi-plus</v-icon>
									</v-btn>
								</aux-input>
								<v-divider />
								<aux-input title="Dirección suministro">
									<v-text-field
										filled
										dense
										v-model="estudio.suministro.direccion"
										hide-details="auto"
										:rules="[rules.req]"
										placeholder="Dirección suministro"
									>
										<template v-slot:append-outer>
											<v-btn class="mt-n2" color="primary" @click="buscarDireccion" :loading="isLoading">
												<v-icon>mdi-google-maps</v-icon>
											</v-btn>
										</template>
									</v-text-field>
								</aux-input>
							</tarjeta-datos>

							<tarjeta-datos header icon="account" class="mt-6" titulo="Cliente">
								<aux-input title="Nombre cliente / Razon Social">
									<v-text-field
										filled
										dense
										v-model="estudio.cliente.nombre"
										hide-details="auto"
										placeholder="Nombre completo / Razon Social"
									/>
								</aux-input>
								<v-divider />
								<aux-input title="DNI/CIF">
									<v-text-field
										filled
										dense
										v-model="estudio.cliente.identificador"
										hide-details="auto"
										:rules="[rules.dni]"
										placeholder="DNI/CIF"
									/>
								</aux-input>
								<v-divider />
								<aux-input title="Nombre Representante">
									<v-text-field
										filled
										dense
										v-model="estudio.cliente.representanteNombre"
										hide-details="auto"
										placeholder="Nombre Completo"
									/>
								</aux-input>
								<v-divider />
								<aux-input title="DNI Representante">
									<v-text-field
										filled
										dense
										v-model="estudio.cliente.representanteDNI"
										hide-details="auto"
										:rules="[rules.dni]"
										placeholder="DNI representante"
									/>
								</aux-input>
								<v-divider />
								<aux-input title="Contactos">
									<div class="d-flex pa-1 flex-column">
										<div class="d-flex align-center" v-for="(c, i) in estudio.cliente.contactos">
											<v-text-field
												filled
												dense
												v-model="estudio.cliente.contactos[i]"
												hide-details="auto"
												class="my-1"
											/>
											<v-btn @click.stop="estudio.cliente.contactos.splice(i, 1)" color="error" icon small>
												<v-icon small>mdi-minus</v-icon>
											</v-btn>
										</div>
									</div>
									<v-btn @click.stop="estudio.cliente.contactos.push(null)" fab small color="primary">
										<v-icon>mdi-plus</v-icon>
									</v-btn>
								</aux-input>
								<v-divider />
							</tarjeta-datos>
						</v-col>
						<v-col cols="12" md="6">
							<div class="d-flex flex-column" style="gap: 24px">
								<tarjeta-datos header icon="file-document-outline" titulo="Datos contrato">
									<aux-input title="Tarifa *">
										<v-select
											filled
											placeholder="Seleccione una tarifa"
											dense
											hide-details="auto"
											v-model="estudio.suministro.tarifa"
											clearable
											:items="selects.tarifas"
											:rules="[rules.req]"
										/>
									</aux-input>

									<v-divider />
									<v-row class="ma-1">
										<v-col cols="12" class="col-md-6" v-for="i in 6" :key="i">
											<aux-input>
												<template v-slot:title>
													<h5>P{{ i }}</h5>
													<small>
														{{
															estudio.suministro.periodos[`p${i}`].potencia
														}}
													</small>
												</template>
												<v-text-field
													filled
													dense
													hide-details="auto"
													:placeholder="`Precio P${i}`"
													v-model="estudio.suministro.periodos[`p${i}`].precio"
													label="Precio"
													:rules="[rules.isNumber]"
												/>
											</aux-input>
										</v-col>
									</v-row>
									<div class="d-flex ma-4 mt-0">
										<v-alert class="ma-0" type="info" dense text>ATR + Coste</v-alert>
									</div>
									<v-divider />
									<aux-input title="Consumo Anual SIPS KWh">
										<v-text-field
											dense
											hide-details="auto"
											filled
											disabled
											placeholder="Introduzca consumo del SIPS"
											v-model="estudio.suministro.consumoAnualSips"
										/>
									</aux-input>
								</tarjeta-datos>

							</div>
						</v-col>
					</v-row>
				</v-tab-item>

				<v-tab-item eager>
					<v-row v-if="!cargandoDatos">
						<v-col cols="12" md="8">
							<tarjeta-datos header titulo="Mapa" icon="map">
								<div style="min-height: 500px; display: grid">
									<Map
										ref="map"
										:center="{
											lat: Number(estudio.latitud),
											lng: Number(estudio.longitud),
										}"
										:zoom="estudio.freezePosition ? 16 : 6"
										v-model="estudio.cubiertas"
										:marker="estudio.freezePosition"
										@changePosition="
											({ latitud, longitud }) => {
												estudio.latitud = latitud;
												estudio.longitud = longitud;
												estudio.freezePosition = true;
											}
										"
									/>
								</div>
							</tarjeta-datos>
						</v-col>
						<v-col cols="12" md="4">
							<tarjeta-datos header titulo="Coordenadas" icon="google-maps">
								<aux-input title="Latitud">
									<v-text-field
										filled
										dense
										hide-details="auto"
										:rules="[rules.req]"
										v-model="estudio.latitud"
										label="Latitud"
									></v-text-field>
								</aux-input>
								<v-divider />
								<aux-input title="Longitud">
									<v-text-field
										filled
										dense
										hide-details="auto"
										:rules="[rules.req]"
										v-model="estudio.longitud"
										label="Longitud"
									></v-text-field>
								</aux-input>
								<v-divider />
								<v-subheader>Cubiertas seleccionadas</v-subheader>
								<div class="d-flex flex-column ma-4" style="gap: 16px">
									<v-card width="100%" v-for="c in cubiertasMarcadas" :key="c.id" class="py-3">
										<v-row no-gutters>
											<v-col cols="auto" class="d-flex justify-center align-center">
												<v-avatar
													@click.stop="estudio.latitud = c.center.lat; estudio.longitud = c.center.lng"
													:color="c.color"
													size="30"
													class="mx-2"
												/>
											</v-col>
											<v-col class="d-flex flex-column mx-2">
												<v-text-field
													v-model="c.nombre"
													single-line
													append-icon="mdi-feather"
													dense
													hide-details="auto"
													:rules="[rules.req, rules.nombreCubierta]"
													:hint="c.area"
													persistent-hint
												/>
											</v-col>
											<v-col cols="auto" class="d-flex justify-center align-center mr-2">
												<v-btn color="error" icon @click.stop="removePolygon(c)">
													<v-icon>mdi-delete</v-icon>
												</v-btn>
											</v-col>
										</v-row>
									</v-card>
								</div>
							</tarjeta-datos>
							<tarjeta-datos header titulo="Otros datos (OPCIONAL)" icon="cog" class="mt-4">
								<aux-input title="Uralita">
									<v-checkbox dense hide-details="auto" class="mt-0" v-model="estudio.uralita"></v-checkbox>
								</aux-input>
								<v-divider />
								<aux-input title="Inclinación">
									<v-text-field filled dense hide-details="auto" v-model="estudio.inclinacion" suffix="º" />
								</aux-input>
								<v-divider />
								<aux-input title="Azimut">
									<v-text-field filled dense hide-details="auto" v-model="estudio.azimut" suffix="º" />
								</aux-input>
								<!--- <v-divider />
								<aux-input title="Material cubierta">
									<v-text-field filled dense hide-details="auto" v-model="estudio.material" />
								</aux-input>-->
							</tarjeta-datos>
						</v-col>
					</v-row>
				</v-tab-item>
			</v-tabs-items>
		</v-form>

		<v-bottom-sheet :value="checksPanel" inset persistent>
			<v-card>
				<v-timeline dense>
					<v-slide-x-transition group>
						<v-timeline-item
							fill-dot
							color="transparent"
							class="align-center"
							v-for="(value, key) in checks"
							:key="key"
						>
							<template v-slot:icon>
								<v-progress-circular
									v-if="
										!isNaN(value.max) &&
										(value.progress == null || value.progress == undefined)
									"
									:value="(value.actual * 100) / value.max"
									color="secondary"
								></v-progress-circular>
								<v-icon v-else-if="value.progress === false">mdi-minus</v-icon>
								<v-icon color="success" v-else-if="value.progress === true">mdi-check</v-icon>
								<v-icon color="error" v-else-if="value.progress === 'error'">mdi-close-octagon-outline</v-icon>
								<v-progress-circular
									v-else-if="value.progress === 'loading'"
									indeterminate
									color="secondary"
								></v-progress-circular>
							</template>
							<h4
								:class="[
									value.progress == 'error' ? 'error--text' : '',
									value.progress === true ? 'success--text' : '',
									value.progress === false ? 'grey--text' : '',
								]"
							>{{ value.texto }}</h4>
						</v-timeline-item>
					</v-slide-x-transition>
				</v-timeline>
			</v-card>
		</v-bottom-sheet>

		<v-bottom-sheet :value="estudio.idTipo === null && !checksPanel" persistent>
			<v-card>
				<h3 class="h3 py-3 text-center">Tipo del estudio</h3>
				<div class="d-flex flex-wrap pa-4 justify-center">
				<TipoEstudio v-for="(tipo, i) in tiposEstudios" :key="i" :tipo="tipo" @click.stop="estudio.idTipo = tipo.idTipo" />
				</div>
			</v-card>
		</v-bottom-sheet>
		        <ConfirmDialog ref="confirm" />
	</v-container>
</template>

<script>
import {
	downloadFile,
	obtenerNombreTarifaML,
	calcularConsumoAnual,
	getSituacionIcon
} from "@/utils/index.js";
import { req, cups, isNumber, ValidateSpanishID } from "@/utils/validations.js";
import extensions from "@/utils/mimeExtensions.js";

export default {
	components: {
		TarjetaDatos: () => import("@/components/TarjetaDatos.vue"),
		DocumentacionEstudio: () => import("@/components/DocumentacionEstudio.vue"),
		AuxInput: () => import("@/components/AuxInput.vue"),
		Map: () => import("@/components/Map.vue"),
		ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
		TipoEstudio: () => import("@/components/TipoEstudio.vue")
	},
	props: {
		noEdit: Boolean,
		_idEstudio: String | Number,
	},
	data() {
		return {
			idEstudio: this.$route.query.idEstudio || this._idEstudio,
			tab: 0,
			estudio: {
				idTipo: null,
				empresa: this.$store.getters.getTokenPayload.empresa,
				cubiertas: [],
				latitud: 40.4165,
				longitud: -3.70256,
				freezePosition: false,
				cliente: {
					contactos: [null]
				},
				suministro: {
					cupsExtras: [],
					direccion: null,
					periodos: {
						p1: {},
						p2: {},
						p3: {},
						p4: {},
						p5: {},
						p6: {},
					},
				},
			},

			debouncer: {
				direccion: null,
			},
			selects: {
				tarifas: ["2.0TD ML", "3.0TD ML", "6.1TD ML"],
			},
			isLoading: false,
			isLoadingCalle: false,
			rules: { req, cups, isNumber, nombreCubierta: this.nombreCubierta, dni: ValidateSpanishID },
			errors: [],
			erroresCampos: null,
			subiendoEstudio: null,
			cargandoDatos: false,
			checksPanel: false,
			checks: {},

			tiposEstudios: [],


			ignorarDoc: false,
			documentos: [],
		};
	},
	computed: {
		tipoSeleccionado() {
			return this.tiposEstudios.find( t => t.idTipo == this.estudio.idTipo )
		},
		cubiertasMarcadas() {
			if (this.estudio.cubiertas == null || this.estudio.cubiertas.length == 0)
				return [];
			const cubiertasMarcadas = this.estudio.cubiertas.filter(
				(c) => c.polygon.visible
			);
			if (cubiertasMarcadas.length > 0 && !this.estudio.freezePosition) {
				const coords = cubiertasMarcadas[0].polygon
					.getPath()
					.getArray()
					.map((p) => [p.lat(), p.lng()]);

				var x = coords.map((xy) => xy[0]);
				var y = coords.map((xy) => xy[1]);
				var cx = (Math.min(...x) + Math.max(...x)) / 2;
				var cy = (Math.min(...y) + Math.max(...y)) / 2;
				this.estudio.latitud = cx;
				this.estudio.longitud = cy;
			}
			return cubiertasMarcadas;
		},
	},
	async mounted() {
		if (this.idEstudio) {
			this.getDatosEstudio();
			this.getDocumentacion();
		}

		this.getTiposEstudio();

	},
	methods: {
		getSituacionIcon,
		nombreCubierta(v) {
			const nombres = this.cubiertasMarcadas.map((c) => c.nombre);
			return (
				nombres.length == [...new Set(nombres)].length ||
				"El nombre no se puede repetir entre cubiertas"
			);
		},
		getDatosEstudio() {
			return new Promise((resolve, reject) => {
				this.$set(this.checks, "cargandoEstudio", {
					progress: "loading",
					texto: "Cargando datos del estudio",
				});
				this.cargandoDatos = true;
				axios({
					method: "GET",
					url: `${process.env.VUE_APP_API_URL}/crearEstudio.php`,
					params: {
						idEstudio: this.idEstudio,
						soloDatos: 1
					},
				})
					.then(async ({ data }) => {
						data = { ...data, datos: JSON.parse(data.datos) };

						if (data.estado !== "Borrador" && data.estado !== "Incidencia" && data.estado !== "Pendiente Revisión") {
							this.$root.$emit('snack', `Este estudio está como ${data.estado}, no puede ser editado`)
							this.$router.go(-1);
							return;
						}

						this.estudio = { ...this.estudio, ...data, ...data.datos, };
						this.checks.cargandoEstudio.progress = true;
						this.cargandoDatos = false;
						resolve();
					})
					.catch((err) => {
						console.log(err);
						console.log(err.response);
						this.checks.cargandoEstudio.progress = "error";
						this.cargandoDatos = false;
						// this.$root.$emit('snack', `Este estudio está como ${data.estado}, no puede ser editado`)
						this.$router.go(-1);
					});
			});
		},
		buscarCups() {
			if (
				this.estudio.suministro.cups == "" ||
				this.estudio.suministro.cups == null
			)
				return;
			this.isLoading = true;
			axios({
				url: "https://sips.sigeenergia.com:61843/SIPSAPIvLast/api/v2/ClientesSips/GetClientesPost",
				method: "POST",
				headers: {
					Authorization: null
				},
				data: {
					CodigoCUPS: this.estudio.suministro.cups,
					NombreEmpresaDistribuidora: "",
					MunicipioPS: "",
					CodigoProvinciaPS: "",
					CodigoPostalPS: "",
					CodigoTarifaATREnVigor: "",
					ListCUPS: "",
					LoadAllDatosCliente: true,
					LoadConsumos: true,
					IsExist: true,
				},
			}).then((res) => {
				// axios({
				// 	url: `https://maps.googleapis.com/maps/api/geocode/json`,
				// 	params: {
				// 		key: "AIzaSyDApxfUsXUHDJ7fYgETkz_nRImNgwjAB2o",
				// 		address: '24 Sussex Drive Ottawa ON'
				// 	}
				// }).then((res) => console.log(res));
				if (res.data.ClientesSips.length == 0) {
					this.isLoading = false;
					return;
				}

				if (
					"004" == res.data.ClientesSips[0].CodigoTarifaATREnVigor ||
					"006" == res.data.ClientesSips[0].CodigoTarifaATREnVigor
				) {
					res.data.ClientesSips[0].PotenciasContratadasEnWP3 =
						res.data.ClientesSips[0].PotenciasContratadasEnWP1;
					res.data.ClientesSips[0].PotenciasContratadasEnWP2 = null;
				}

				let i = this.estudio.suministro.cups.substring(2, 8);
				let dist;
				"003110" == i
					? (dist = "0023")
					: "003130" == i
						? (dist = "0029")
						: "003144" == i
							? (dist = "0396")
							: "003150" == i
								? (dist = "0288")
								: "003160" == i
									? (dist = "0363")
									: "003140" == i
										? res.data.ClientesSips.length > 0 &&
											res.data.ClientesSips[0].CodigoPostalPS.startsWith("50")
											? (dist = "0029")
											: (dist = "0024")
										: (dist = this.estudio.suministro.cups.substring(2, 6));

				let aux = res.data.ClientesSips[0];

				var tarifaBOE = obtenerNombreTarifaML(
					aux.CodigoTarifaATREnVigor
				).actual;

				this.estudio.suministro.tarifa = this.selects.tarifas.find(
					(tarifa) => tarifa == tarifaBOE
				);

				if (tarifaBOE == "2.0TD ML") {
					if (
						aux.PotenciasContratadasEnWP1 == null ||
						aux.PotenciasContratadasEnWP1 == 0
					) {
						if (
							aux.PotenciasContratadasEnWP2 != null ||
							aux.PotenciasContratadasEnWP2 != 0
						) {
							aux.PotenciasContratadasEnWP1 = aux.PotenciasContratadasEnWP2;
							aux.PotenciasContratadasEnWP2 = 0;
							if (
								aux.PotenciasContratadasEnWP3 == null ||
								aux.PotenciasContratadasEnWP3 == 0
							) {
								aux.PotenciasContratadasEnWP3 = aux.PotenciasContratadasEnWP1;
							}
						}
					} else if (
						aux.PotenciasContratadasEnWP3 == null ||
						aux.PotenciasContratadasEnWP3 == 0
					) {
						aux.PotenciasContratadasEnWP3 = aux.PotenciasContratadasEnWP1;
					}
					aux.PotenciasContratadasEnWP2 = 0;
					aux.PotenciasContratadasEnWP4 = null;
					aux.PotenciasContratadasEnWP5 = null;
					aux.PotenciasContratadasEnWP6 = null;
				}

				if (tarifaBOE == "3.0TD ML" || tarifaBOE == "6.1TD ML") {
					if (
						(aux.PotenciasContratadasEnWP4 == null ||
							aux.PotenciasContratadasEnWP4 == 0) &&
						(aux.PotenciasContratadasEnWP5 == null ||
							aux.PotenciasContratadasEnWP5 == 0) &&
						(aux.PotenciasContratadasEnWP6 == null ||
							aux.PotenciasContratadasEnWP6 == 0)
					) {
						aux.PotenciasContratadasEnWP6 = aux.PotenciasContratadasEnWP3;
						aux.PotenciasContratadasEnWP3 = aux.PotenciasContratadasEnWP2;
						aux.PotenciasContratadasEnWP4 = aux.PotenciasContratadasEnWP2;
						aux.PotenciasContratadasEnWP5 = aux.PotenciasContratadasEnWP2;
					}
				}
				res.data.ClientesSips[i] = aux;

				this.estudio.suministro.codDistribuidora = dist;
				if (res.data.DatosTitular) {
					try {
						this.estudio.suministro.direccion = `${aux.DesMunicipioPS} (${aux.DesProvinciaPS}) ${res.data.DatosTitular[0].DireccionSuministro}`;
					} catch (err) {
						console.error(err);
					}
				}
				this.estudio.suministro.codPostal = aux.CodigoPostalPS;

				var consumosEstimados = calcularConsumoAnual(
					res.data.ConsumosSips,
					this.estudio.suministro.cups,
					res.data.ClientesSips[0].CodigoTarifaATREnVigor
				);

				this.$set(this.estudio.suministro.periodos, "p1", {
					potencia: aux.PotenciasContratadasEnWP1,
					estimado: consumosEstimados.a1,
					precio: this.estudio.suministro.periodos && this.estudio.suministro.periodos.p1
              ? this.estudio.suministro.periodos.p1.precio
              : null,
				});
				this.$set(this.estudio.suministro.periodos, "p2", {
					potencia: aux.PotenciasContratadasEnWP2,
					estimado: consumosEstimados.a2,
					precio: this.estudio.suministro.periodos && this.estudio.suministro.periodos.p1
              ? this.estudio.suministro.periodos.p2.precio
              : null,
				});
				this.$set(this.estudio.suministro.periodos, "p3", {
					potencia: aux.PotenciasContratadasEnWP3,
					estimado: consumosEstimados.a3,
					precio: this.estudio.suministro.periodos && this.estudio.suministro.periodos.p1
              ? this.estudio.suministro.periodos.p3.precio
              : null,
				});
				this.$set(this.estudio.suministro.periodos, "p4", {
					potencia: aux.PotenciasContratadasEnWP4,
					estimado: consumosEstimados.a4,
					precio: this.estudio.suministro.periodos && this.estudio.suministro.periodos.p1
              ? this.estudio.suministro.periodos.p4.precio
              : null,
				});
				this.$set(this.estudio.suministro.periodos, "p5", {
					potencia: aux.PotenciasContratadasEnWP5,
					estimado: consumosEstimados.a5,
					precio: this.estudio.suministro.periodos && this.estudio.suministro.periodos.p1
              ? this.estudio.suministro.periodos.p5.precio
              : null,
				});
				this.$set(this.estudio.suministro.periodos, "p6", {
					potencia: aux.PotenciasContratadasEnWP6,
					estimado: consumosEstimados.a6,
					precio: this.estudio.suministro.periodos && this.estudio.suministro.periodos.p1
              ? this.estudio.suministro.periodos.p6.precio
              : null,
				});

				this.estudio.suministro.consumoAnualSips = consumosEstimados.total;

				this.isLoading = false;
			});
		},
		async buscarDireccion() {
			this.isLoading = true;
			try {
				if (this.estudio.freezePosition) {
					this.isLoading = false;
					return
				};
				const { lat, lng } = await this.$refs.map.buscarDireccion(this.estudio.suministro.direccion)
				// const { data: coords } = await axios({
				// 	url: `https://maps.googleapis.com/maps/api/geocode/json`,
				// 	params: {
				// 		key: "AIzaSyDApxfUsXUHDJ7fYgETkz_nRImNgwjAB2o",
				// 		address: encodeURIComponent(this.estudio.suministro.direccion),
				// 	}
				// }
				// );
				this.estudio.latitud = lat;
				this.estudio.longitud = lng;
			} catch (err) {
				console.error(err);
			}
			this.isLoading = false;
		},
		estudioBuilder(vm) {
			return new Object({
				datos: {
					...vm.estudio,
					datos: null,
					cubiertas: vm.estudio.cubiertas
						.filter((c) => c.polygon.visible)
						.map((c) => ({ ...c, polygon: c.polygon.getPath().getArray() })),
					cronograma: null,
					mensajes: null,
				},

				guardarBorrador: function () {
					return new Promise((resolve, reject) => {
						axios({
							method: "POST",
							url: `${process.env.VUE_APP_API_URL}/crearEstudio.php`,
							data: {
								datos: { ...this.datos },
								idEstudio: vm.idEstudio || null,
								estado: "Borrador",
								idTipo: vm.estudio.idTipo
							},
						})
							.then(({ data }) => resolve(data))
							.catch((err) => {
								console.error(err);
								reject(err);
							});
					});
				},

				enviarVerificacion: function () {
					return new Promise((resolve, reject) => {
						try {
							if (!vm.$root.acceso('EDITAR_ESTUDIOS') || this.datos.estado == 'Borrador') {
								delete this.datos.estado;
							}
							axios({
								method: "POST",
								url: `${process.env.VUE_APP_API_URL}/crearEstudio.php`,
								data: {
									datos: this.datos,
									idEstudio: vm.idEstudio || null,
									estado: this.datos.estado || "Pendiente Revisión",
									idTipo: vm.estudio.idTipo
								},
							})
								.then(({ data }) => resolve(data))
								.catch((err) => {
									console.error(err);
									reject(err);
								});
						} catch (e) {
							console.error('ERROR ==>', e)
							console.error('ERROR ==>', e.response)
						}
					});
				},
			});
		},
		async enviarEstudio(borrador) {
			if (!this.$refs.formulario.validate() && !borrador) {
				this.erroresCampos = true;
				return;
			}
			this.errors = [];

			if (borrador)
				this.$set(this.checks, "guardandoBorrador", {
					progress: false,
					texto: "Guardando Borrador",
				});
			else
				this.$set(this.checks, "subiendoEstudio", {
					progress: false,
					texto: "Subiendo Estudio a verificación",
				});

			this.cargandoDatos = true;

			let estudioPotencial = new this.estudioBuilder(this);

			if (borrador) {
				try {
					this.checks.guardandoBorrador.progress = "loading";
					const idBorrador = await estudioPotencial.guardarBorrador();
					this.checks.guardandoBorrador.progress = true;
					if (borrador && idBorrador) {
						this.$router.push({
							name: "EnviarEstudio",
							query: { idEstudio: idBorrador },
						});
						this.cargandoDatos = false;
						return;
					} else if (borrador && this.idEstudio) {
						this.$router.push({
							name: "Borradores",
						});
						this.cargandoDatos = false;
						return;
					}
					this.checks.guardandoBorrador.progress = true;
				} catch (e) {
					console.error(e);
					this.errors.push(e.response.data);
					this.checks.guardandoBorrador.progress = "error";
					this.cargandoDatos = false;
					return;
				}
			} else {
				try {
					this.checks.subiendoEstudio.progress = "loading";
					await estudioPotencial.enviarVerificacion();
					this.checks.subiendoEstudio.progress = true;
				} catch (e) {
					console.error(e);
					this.errors.push(e.response.data);
					this.checks.subiendoEstudio.progress = "error";
					this.cargandoDatos = false;
					return;
				}
			}

			this.$router.push({ name: "Estudios" });

			// try {
			// 	this.checks.subiendoEstudio.progress = "loading";
			// 	await estudioPotencial.guardarBorrador();
			// 	this.checks.subiendoEstudio.progress = true;
			// } catch (e) {
			// 	console.error(e);
			// 	this.checks.subiendoEstudio.progress = "error";
			// 	this.cargandoDatos = true;
			// 	return;
			// }

			// .then(() => {
			// 	this.checks.subiendoEstudio.progress = true;
			// 	this.$router.push({ name: "Estudios" });
			// })
			// .catch((err) => {
			// 	console.log(err);
			// 	console.log(err.response);
			// 	this.errors.push(err.response.data);
			// 	this.checks.subiendoEstudio.progress = "error";
			// 	this.cargandoDatos = false;
			// });
		},
		getDocumentacion() {
			if (!this.idEstudio) return;
			return new Promise((resolve, reject) => {
				axios({
					method: "GET",
					url: `${process.env.VUE_APP_API_URL}/subir_documentacion.php`,
					params: {
						idBorrador: this.idEstudio,
					},
				})
					.then(({ data }) => {
						this.documentos = Object.values(data).map((f) => ({ name: f }));
						resolve();
					})
					.catch((e) => {
						// console.trace(e);
						this.$root.$emit(
							"snack",
							"No se ha podido encontrar la documentación"
						);
						reject();
					});
			});
		},
		async eliminarEstudio() {
      if (!await this.$refs.confirm.open('Eliminar estudio', '¿Estás seguro de que quieres eliminar este borrador?', { confirmColor: 'error', color: 'error' })) return;
			
			axios({
				method: "DELETE",
				url: `${process.env.VUE_APP_API_URL}/crearEstudio.php`,
				data: {
					idBorrador: this.idEstudio,
					idEstudio: this.idEstudio,
					motivo: 'Eliminación borrador',
				},
			}).then(res => {
				this.$router.push({ name: 'Borradores' })
			})
		},
		removePolygon(pol) {
			pol.polygon.visible = false;
			this.estudio.cubiertas.splice(
				this.estudio.cubiertas.findIndex((item) => item.id == pol.id),
				1,
				pol
			);
		},
		async getTiposEstudio() {
			try {
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URL}/opciones/tiposEstudio.php`,
      });
      this.tiposEstudios = data;
    } catch (e) {
      console.log(e);
      this.$root.$emit("snack", "Ha ocurrido un error");
    }
		}
	},
	watch: {
		cargandoDatos(val) {
			if (val) this.checksPanel = true;
			else {
				setTimeout(() => {
					this.checksPanel = false;
					this.checks = {};
				}, 1000);
			}
		},
		"estudio.suministro.direccion"(val) {
			if (!val) return;
			clearTimeout(this.debouncer.direccion);
			this.debouncer.direccion = setTimeout(() => {
				//TODO: BUSCAR EN MAPS
				this.buscarDireccion();
			}, 1000);
		},
	},
};
</script>

<style>
</style>
